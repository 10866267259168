import "jquery";
import "jquery-validation";
import Chart from "chart.js/auto";
$(document).ready(function () {
  const weatherDataText = $("#weather-data").text();
  const weatherData = JSON.parse(weatherDataText);

  const dayNames = {
    lundi: "Lun",
    mardi: "Mar",
    mercredi: "Mer",
    jeudi: "Jeu",
    vendredi: "Ven",
    samedi: "Sam",
    dimanche: "Dim",
  };

  const formattedDates = weatherData.map((entry) => {
    try {
      const dateStr = entry.date;
      const dateObject = new Date(dateStr);

      if (isNaN(dateObject.getTime())) {
        throw new Error(`Invalid date format: ${dateStr}`);
      }

      const fullDayName = dateObject.toLocaleString("fr-FR", { weekday: "long" });
      const dayOfWeek = dayNames[fullDayName];
      const dayOfMonth = dateObject.getDate();
      return `${dayOfWeek || "N/A"} ${dayOfMonth}`;
    } catch (error) {
      console.error(error.message);
      return "N/A";
    }
  });

  const maxTemps = weatherData.map((entry) => parseInt(entry.maxtempC, 10));
  const minTemps = weatherData.map((entry) => parseInt(entry.mintempC, 10));

  // Calculate the max and min precipitation for each day
  const maxPrecipitations = weatherData.map((entry) => {
    const hourlyData = entry.hourly; // Assuming this is an array of hourly data
    const maxPrecip = Math.max(...hourlyData.map((hour) => parseFloat(hour.precipMM)));
    return maxPrecip;
  });

  // const minPrecipitations = weatherData.map((entry) => {
  //   const hourlyData = entry.hourly; // Assuming this is an array of hourly data
  //   const minPrecip = Math.min(...hourlyData.map((hour) => parseFloat(hour.precipMM)));
  //   return minPrecip;
  // });

  const ctx = $("#tempChart")[0].getContext("2d");

  const tempChart = new Chart(ctx, {
    type: "line",
    data: {
      labels: formattedDates,
      datasets: [
        {
          label: "Max T(°C)",
          data: maxTemps,
          borderColor: "#ff9c00",
          backgroundColor: "rgba(255, 156, 0, 0.1)",
          borderWidth: 2,
          pointBackgroundColor: "#ff9c00",
          pointBorderColor: "#ff9c00",
          pointRadius: 4,
          tension: 0.4,
          fill: false,
          yAxisID: "y-temp",
        },
        {
          label: "Min T(°C)",
          data: minTemps,
          borderColor: "#1e88b4",
          backgroundColor: "rgba(30, 136, 180, 0.1)",
          borderWidth: 2,
          pointBackgroundColor: "#1e88b4",
          pointBorderColor: "#1e88b4",
          pointRadius: 4,
          tension: 0.4,
          fill: false,
          yAxisID: "y-temp",
        },
        {
          label: "Pluie(mm)",
          data: maxPrecipitations, // Maximum precipitation of the day
          type: "bar",
          backgroundColor: "#42beeb",
          borderColor: "#42beeb",
          borderWidth: 1,
          yAxisID: "y-precip",
          fill: true,
          barThickness: 12,
        },
        // ,
        // {
        //   label: "Min Precipitations (mm)",
        //   data: minPrecipitations, // Minimum precipitation of the day
        //   type: "bar",
        //   backgroundColor: "rgba(0, 255, 0, 0.6)",
        //   borderColor: "#00ff00",
        //   borderWidth: 1,
        //   yAxisID: "y-precip",
        //   fill: true,
        //   barThickness: 12,
        // },
      ],
    },
    options: {
      scales: {
        "y-temp": {
          beginAtZero: true,
          type: "linear",
          position: "left",
          ticks: {
            stepSize: 5,
            font: {
              size: 14,
            },
            color: "rgba(0, 0, 0, 0.5)",
          },
          grid: {
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
        "y-precip": {
          beginAtZero: true,
          type: "linear",
          position: "right",
          ticks: {
            stepSize: 2.5,
            font: {
              size: 14,
            },
            color: "#42beeb",
          },
          grid: {
            display: false,
          },
        },
        x: {
          ticks: {
            font: {
              size: 14,
            },
            color: "rgba(0, 0, 0, 0.5)",
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            fontColor: "rgba(0, 0, 0, 0.7)",
          },
        },
      },
      elements: {
        line: {
          borderJoinStyle: "round",
        },
      },
    },
  });
});
// $(document).ready(function () {
//   const weatherDataText = $("#weather-data").text();
//   const weatherData = JSON.parse(weatherDataText);

//   const dayNames = {
//     lundi: "Lun",
//     mardi: "Mar",
//     mercredi: "Mer",
//     jeudi: "Jeu",
//     vendredi: "Ven",
//     samedi: "Sam",
//     dimanche: "Dim",
//   };

//   const formattedDates = weatherData.map((entry) => {
//     try {
//       const dateStr = entry.date;
//       const dateObject = new Date(dateStr);

//       if (isNaN(dateObject.getTime())) {
//         throw new Error(`Invalid date format: ${dateStr}`);
//       }

//       const fullDayName = dateObject.toLocaleString("fr-FR", { weekday: "long" });
//       const dayOfWeek = dayNames[fullDayName];
//       const dayOfMonth = dateObject.getDate();
//       return `${dayOfWeek || "N/A"} ${dayOfMonth}`;
//     } catch (error) {
//       console.error(error.message);
//       return "N/A";
//     }
//   });

//   const maxTemps = weatherData.map((entry) => parseInt(entry.maxtempC, 10));
//   const minTemps = weatherData.map((entry) => parseInt(entry.mintempC, 10));

//   // Calculate the average precipitation for each day
//   const averagePrecipitations = weatherData.map((entry) => {
//     const hourlyData = entry.hourly; // Assuming this is an array of hourly data
//     const totalPrecipitation = hourlyData.reduce((sum, hour) => sum + parseFloat(hour.precipMM), 0);
//     const averagePrecipitation = totalPrecipitation / hourlyData.length;
//     console.log(averagePrecipitation);
//     return averagePrecipitation;
//   });

//   const ctx = $("#tempChart")[0].getContext("2d");

//   const tempChart = new Chart(ctx, {
//     type: "line",
//     data: {
//       labels: formattedDates,
//       datasets: [
//         {
//           label: "Max Temperature (°C)",
//           data: maxTemps,
//           borderColor: "#ff9c00",
//           backgroundColor: "rgba(255, 156, 0, 0.1)",
//           borderWidth: 2,
//           pointBackgroundColor: "#ff9c00",
//           pointBorderColor: "#ff9c00",
//           pointRadius: 4,
//           tension: 0.4,
//           fill: false,
//           yAxisID: "y-temp",
//         },
//         {
//           label: "Min Temperature (°C)",
//           data: minTemps,
//           borderColor: "#1e88b4",
//           backgroundColor: "rgba(30, 136, 180, 0.1)",
//           borderWidth: 2,
//           pointBackgroundColor: "#1e88b4",
//           pointBorderColor: "#1e88b4",
//           pointRadius: 4,
//           tension: 0.4,
//           fill: false,
//           yAxisID: "y-temp",
//         },
//         {
//           label: "Precipitations (mm)",
//           data: averagePrecipitations, // Using the daily average precipitation
//           type: "bar",
//           backgroundColor: "rgba(0, 123, 255, 0.6)",
//           borderColor: "#007bff",
//           borderWidth: 1,
//           yAxisID: "y-precip",
//           fill: true,
//           barThickness: 12,
//         },
//       ],
//     },
//     options: {
//       scales: {
//         "y-temp": {
//           beginAtZero: true,
//           type: "linear",
//           position: "left",
//           ticks: {
//             stepSize: 5,
//             font: {
//               size: 14,
//             },
//             color: "rgba(0, 0, 0, 0.5)",
//           },
//           grid: {
//             color: "rgba(0, 0, 0, 0.1)",
//           },
//         },
//         "y-precip": {
//           beginAtZero: true,
//           type: "linear",
//           position: "right",
//           ticks: {
//             stepSize: 2.5,
//             font: {
//               size: 14,
//             },
//             color: "rgba(0, 0, 0, 0.5)",
//           },
//           grid: {
//             display: false,
//           },
//         },
//         x: {
//           ticks: {
//             font: {
//               size: 14,
//             },
//             color: "rgba(0, 0, 0, 0.5)",
//           },
//           grid: {
//             display: false,
//           },
//         },
//       },
//       plugins: {
//         legend: {
//           display: true,
//           labels: {
//             fontColor: "rgba(0, 0, 0, 0.7)",
//           },
//         },
//       },
//       elements: {
//         line: {
//           borderJoinStyle: "round",
//         },
//       },
//     },
//   });
// });
