import "jquery";

import "jquery-validation";

import "jquery-ui/ui/widgets/autocomplete";

$(document).ready(function () {
  $("#hamburger").on("click", function (e) {
    e.preventDefault();

    $(".hamburger").toggleClass("animate");

    $(".bar").toggleClass("animate");

    $(".menu_list").toggleClass("hide_menu show_menu");

    $("body").toggleClass("animate");
  });

  $(document).on("click", function (e) {
    if (!$(e.target).closest(".menu_list, #hamburger").length) {
      $(".hamburger").removeClass("animate");

      $(".bar").removeClass("animate");

      $(".menu_list").removeClass("show_menu").addClass("hide_menu");

      $("body").removeClass("animate");
    }
  });

  $.validator.addMethod(
    "email",

    function (value, element) {
      return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z.]{2,5}$/i.test(value);
    },

    "Adresse e-mail non valide: Veuillez entrer une adresse e-mail valide."
  );

  $.validator.addMethod(
    "telephone",

    function (value, element) {
      return this.optional(element) || /^[0-9]{10,14}$/i.test(value);
    },

    "Format invalide. (Exemple: 0611111111)"
  );

  //$("#form_telephone").mask("06 00 00 00 00");

  $(document).ready(function () {
    $("#form_contact").validate({
      errorElement: "samp",

      rules: {
        "contact[nom]": {
          required: true,

          minlength: 3,
        },

        "contact[prenom]": {
          required: true,

          minlength: 3,
        },

        "contact[email]": {
          required: true,

          email: true,
        },

        "contact[telephone]": {
          required: true,

          phone: true,
        },

        "contact[message]": {
          required: true,
        },
      },

      messages: {
        "contact[nom]": {
          required: "Veuillez saisir votre nom",

          minlength: "Votre nom pas correct",
        },

        "contact[prenom]": {
          required: "Veuillez saisir votre prenom",

          minlength: "Votre prenom pas correct",
        },

        "contact[email]": {
          required: "Veuillez saisir votre email",

          email: "Votre email pas correct",
        },

        "contact[telephone]": {
          required: "Veuillez saisir votre Téléphone",

          phone: "Votre Téléphone pas correct",
        },

        "contact[message]": {
          required: "Veuillez saisir votre message",
        },
      },
    });
  });

  const validatesearch = ["#search_form", "#search_form2"];

  for (let i = 0; i < validatesearch.length; i++) {
    const carousevalidatesearch = $(validatesearch[i]);

    carousevalidatesearch.validate({
      errorElement: "samp",

      rules: {
        motecle: {
          required: true,

          minlength: 3,
        },
      },

      messages: {
        motecle: {
          required: "Veuillez saisir un ville",

          minlength: "Votre motecle pas correct",
        },
      },
    });
  }

  $("#previsionsjours_slider").owlCarousel({
    margin: 10,

    loop: true,

    dots: false,

    nav: true,

    responsiveClass: true,

    autoplayTimeout: 8000,

    autoplayHoverPause: true,

    responsive: {
      0: {
        items: 2,
      },

      567: {
        items: 3,
      },

      911: {
        items: 4,
      },

      1199: {
        items: 5,
      },
    },
  });

  const carouselIds = [
    "#selected_ville_hourly_weather_slider",

    "#selected_ville_hourly_weather_slider_1",

    "#selected_ville_hourly_weather_slider_2",

    "#selected_ville_hourly_weather_slider_3",
  ];

  for (let i = 0; i < carouselIds.length; i++) {
    const carouselElement = $(carouselIds[i]);

    // Find the selected item only within the current carousel

    const selectedItem = carouselElement.find(".item_selected_ville_hourly_weather_slider_now_hour");

    // Set default startPosition to 1 if selectedItem is not found

    let startPosition;

    if (selectedItem.length) {
      startPosition = selectedItem.index(); // Use the selected item's index if it exists
    } else {
      startPosition = 0; // Default to index 1 if no selected item is found
    }

    carouselElement.owlCarousel({
      items: 8,

      loop: false,

      dots: false,

      nav: true,

      startPosition: startPosition, // Use the calculated index or default to 1

      responsiveClass: true,

      responsive: {
        0: {
          items: 2,
        },

        567: {
          items: 4,
        },

        911: {
          items: 5,
        },

        1199: {
          items: 8,
        },
      },
    });
  }

  // const gallery_projetnextIcon_blue = '<img src="/build/assets/images/slider_arrowblue.png">';

  var startPosition = $(
    ".group_item_selected_ville_hourly_weather:has(.item_selected_ville_hourly_weather_slider_now_hour)"
  ).index();

  $("#selected_ville_hourly_weather_slider_mobile").owlCarousel({
    items: 8,

    loop: false,

    dots: false,

    nav: true,

    // navText: [gallery_projetnextIcon_blue, gallery_projetnextIcon_blue],

    responsiveClass: true,

    startPosition: startPosition,

    responsive: {
      0: {
        items: 1,
      },

      567: {
        items: 1,
      },

      911: {
        items: 1,
      },

      1199: {
        items: 8,
      },
    },
  });

  // const gallery_projetnextIcon = '<img src="/build/assets/images/slider_arrow.png">';

  $("#home_actualites_slider").owlCarousel({
    items: 3,

    margin: 20,

    loop: true,

    dots: false,

    nav: true,

    // navText: [gallery_projetnextIcon, gallery_projetnextIcon],

    responsive: {
      0: {
        items: 1,
      },

      567: {
        items: 1,
      },

      767: {
        items: 2,
      },

      1199: {
        items: 3,
      },
    },
  });

  var selectedIndex = $("#previsionsjours_slider_days_list").find(".indextoshow").index();

  $("#previsionsjours_slider_days_list").owlCarousel({
    items: 6,

    margin: 20,

    loop: false,

    dots: false,

    nav: false,

    startPosition: selectedIndex >= 0 ? selectedIndex : 0,

    responsive: {
      0: {
        items: 3,
      },

      567: {
        items: 4,
      },

      767: {
        items: 4,
      },

      991: {
        items: 5,
      },

      1199: {
        items: 6,
      },
    },
  });

  const $sliderEl = $("#range");

  const $sliderValue = $(".value");

  $sliderEl.on("input", function (event) {
    const tempSliderValue = event.target.value;

    $sliderValue.text(tempSliderValue);

    const progress = (tempSliderValue / $sliderEl.attr("max")) * 100;
  });

  // $(".search_input").autocomplete({
  //   source: function (request, response) {
  //     $.ajax({
  //       url: "/autocompleteville", // URL for your API

  //       dataType: "json",

  //       data: {
  //         term: request.term, // The term being searched for
  //       },

  //       success: function (data) {
  //         response(data); // Pass the data to the autocomplete widget
  //       },
  //     });
  //   },

  //   minLength: 2, // Minimum length of input before autocomplete triggers

  //   select: function (event, ui) {
  //     $(".ville_slug").val(ui.item.slug);

  //     console.log("Selected: " + ui.item.slug); // Log the selected slug (optional)
  //   },

  //   create: function () {
  //     $(this).data("ui-autocomplete")._renderItem = function (ul, item) {
  //       return $("<li>")
  //         .append("<div class='autocomplete-item' value='" + item.slug + "'>" + item.value + "</div>")

  //         .appendTo(ul);
  //     };
  //   },

  //   open: function (event, ui) {
  //     var position = $(this).offset();

  //     var inputHeight = $(this).outerHeight();

  //     var menu = $(this).autocomplete("widget");

  //     menu.css({
  //       position: "absolute",

  //       top: position.top + inputHeight + "px",

  //       left: position.left + "px",

  //       width: $(this).outerWidth() + "px", // Optional: to match the width of the input
  //     });
  //   },
  // });
  $(".search_input").autocomplete({
    source: function (request, response) {
      $.ajax({
        url: "/autocompleteville", // URL for your autocomplete API
        dataType: "json",
        data: {
          term: request.term, // The term being searched for
        },
        success: function (data) {
          response(data); // Pass the data to the autocomplete widget
        },
      });
    },
    minLength: 2, // Minimum length of input before autocomplete triggers
    select: function (event, ui) {
      $(".ville_slug").val(ui.item.slug); // Set the selected slug
      $(this).closest("form").submit(); // Submit the form automatically
    },
    create: function () {
      $(this).data("ui-autocomplete")._renderItem = function (ul, item) {
        return $("<li>")
          .append("<div class='autocomplete-item' value='" + item.slug + "'>" + item.value + "</div>")
          .appendTo(ul);
      };
    },
    open: function (event, ui) {
      var position = $(this).offset();
      var inputHeight = $(this).outerHeight();
      var menu = $(this).autocomplete("widget");
      menu.css({
        position: "absolute",
        top: position.top + inputHeight + "px",
        left: position.left + "px",
        width: $(this).outerWidth() + "px", // Optional: to match the width of the input
      });
    },
  });
});
