import "jquery";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/app.css";
import "owl.carousel";

global.$ = global.jQuery = $;

import "./styles/main.css";
import "./js/main";
import "./js/chart_graph";
